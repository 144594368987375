import { EncodeObject } from '@cosmjs/proto-signing'
import { IndexedTx } from '@cosmjs/stargate'
import { TxEvent } from '@cosmjs/tendermint-rpc/build/tendermint37'
import { TxSearchResponse } from '@cosmjs/tendermint-rpc/build/tendermint37/responses'
import * as bech32 from "bech32"
import { AddressUtils, CarbonSDK, CarbonTx } from 'carbon-js-sdk'
import { MarketStats } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/marketstats/export'
import { Transaction } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/export'
import { Message } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/message'
import { Network } from 'carbon-js-sdk/lib/constant'
import { MarketStatItem, MarketType } from 'js/models/utils'
import { BN_ZERO, SHIFT_DECIMALS, bnOrZero, parseNumber } from 'js/utils'
import { camelCase, forEach, isArray, isPlainObject } from 'lodash'
import Long from 'long'

export function camelizeKeys(o: object): object {
  const newO: any = {}
  forEach(o, (value: any, key: string): void => {
    if (isPlainObject(value) || isArray(value)) {
      // tslint:disable:no-parameter-reassignment
      value = camelizeKeys(value)
    }
    newO[camelCase(key)] = value
  })
  return newO
}

export function shorten(s?: string, num: number = 10): string {
  if (!s) return ''
  return s.substring(0, num).concat('...')
}

export function shortenHash(s?: string, num?: number): string {
  if (!s) return ''
  return s.substring(0, num).concat('...').concat(s.substring(s.length - 3))
}

export function capitalizeFirstLetter(s?: string) {
  if (!s) return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function copyTextToClipBoard(s: string) {
  let dummy = document.createElement("textarea")
  document.body.appendChild(dummy)
  dummy.value = s
  dummy.select()
  document.execCommand("copy")
  document.body.removeChild(dummy)
}

export const actionNameGenFactory = (prefix: string) => {
  return (actionName: string) => {
    if (!prefix.length) return actionName
    return `${prefix}_${actionName}`
  }
}


export const MARKETS_PAIR: any = [
  "swth_eth1",
  "swth_eth",
  "swth_usdc1",
  "eth1_usdc1",
  "cel1_usdc1",
  "nex1_usdc1",
  "nneo2_usdc1",
  "wbtc1_usdc1",
  "eth1_wbtc1",
  "nneo2_eth1",
  "swth_busd1",
  "busd1_usdc1",
  "nneo2_busd1",
  "cel1_busd1",
  "wbtc1_btcb1",
  "bnb1_eth1",
  "btc_m21",
  "eth_m21",
  "swth_usdc1",
  "eth_h21",
  "btc_h21",
  "cel_eth",
  "bnb1_busd1",
  "btcb1_busd1",
  "trb1_busd1",
]

export const indexedTxArrayToTransactionArray = (indexedTx: IndexedTx[]) => {
  const transactionArr: Transaction[] = []
  for (const tx of indexedTx) {
    const decodedTx = CarbonTx.decode(tx.tx)
    if (!decodedTx?.body) {
      continue
    }
    const messages = decodedTx.body.messages as any[]

    const initTx: Transaction = {
      hash: tx.hash,
      blockHeight: new Long(tx.height),
      messages: messages,
      address: '',
      code: tx.code,
      memo: '',
      gasUsed: new Long(tx.gasUsed),
      gasWanted: new Long(tx.gasWanted)
    }
    transactionArr.push(initTx)
  }
  return transactionArr
}

export const decodeUintArray = (arr: Uint8Array) => {
  return Buffer.from(arr).toString("hex")
}

const msgTypes = Object.entries(CarbonTx.Types);

export const getTxMsgs = (transaction: IndexedTx | TxEvent, raw?: boolean) => {
  try {
    const formatMsgName = (name?: string) => name?.replace(/^Msg/, "").replace(/([A-Z])/g, " $1");
    const carbonTx = CarbonTx.decode(transaction.tx)
    const msgNames = carbonTx?.body?.messages.map((msg) => {
      const typeUrl = (msg as EncodeObject).typeUrl;
      if (raw) return typeUrl;
      const msgName = msgTypes.find((entry) => typeUrl === entry[1])?.[0];
      return formatMsgName(msgName);
    });
    return msgNames
  } catch (error) {
    console.error("getTxMsgs failed")
    console.error(error);
  }
}

export const getTxMsgTypes = (transaction: Transaction) => {
  const formatMsgName = (name?: string) => name?.replace(/^Msg/, "").replace(/([A-Z])/g, " $1");
  const msgNames = transaction.messages.map((msg) => {
    const typeUrl = (msg as any).messageType.messageType;
    const msgName = msgTypes.find((entry) => typeUrl === entry[1])?.[0];
    return formatMsgName(msgName);
  });
  return msgNames
}

// export const getMsgTypeFromTxEventResult = (txData: TxData) => {
//   const messages = txData.events.find((e: any) => e.type === 'message')
//   const action = messages?.attributes.find((a: any) => a.key === 'action')
//   return action?.value ? decodeUintArray(action?.value) : undefined
// }

export const txReponseArrayToTransactionArray = (txResponse: TxSearchResponse) => {
  const transactionArr: Transaction[] = []
  for (const tx of txResponse.txs) {
    const decodedTx = CarbonTx.decode(tx.tx)
    if (!decodedTx?.body) {
      continue
    }
    const messages = decodedTx.body.messages as any
    const addressMsg = (decodedTx?.body?.messages?.[0] as any)?.value
    const address = addressMsg?.creator ?? addressMsg.fromAddress ?? '-'
    const parsedHash = Buffer.from(tx.hash).toString("hex").toUpperCase()
    let parsedLog = []
    let message
    try {
      parsedLog = JSON.parse(JSON.parse(JSON.stringify(tx.result.log)))
      const events = parsedLog[0].events
      message = events.find((e: any) => e.type === 'message')
    }
    catch {
      message = {
        attributes: [
          {
            key: "action",
            value: messages[0].typeUrl
          }
        ]
      }
    }

    const action = message?.attributes?.find((a: any) => a.key === 'action')
    const msgType = action.value
    const messageArr: Message[] = [{
      hash: parsedHash,
      message: messages,
      messageType: {
        messageType: msgType
      },
    }]
    const initTx: Transaction = {
      hash: parsedHash,
      blockHeight: new Long(tx.height),
      messages: messageArr,
      address: address,
      code: tx.result.code,
      memo: '',
      gasUsed: new Long(tx.result.gasUsed),
      gasWanted: new Long(tx.result.gasWanted)
    }
    transactionArr.push(initTx)
  }
  return transactionArr.reverse()
}

export const getSWTHAddressFromValidatorOperatorAddress = (validatorOperatorAddress: string | undefined, network: Network | undefined) => {
  const operatorAddressDecoded = validatorOperatorAddress ? bech32.decode(validatorOperatorAddress) : undefined
  const operatorAddressBuffer = operatorAddressDecoded ? Buffer.from(bech32.fromWords(operatorAddressDecoded.words)) : undefined
  return operatorAddressBuffer ? AddressUtils.SWTHAddress.encode(operatorAddressBuffer, {
    network,
    type: 'main'
  }) : ""
}

export function parseMarketStats(marketStats: MarketStats): MarketStatItem {
  return {
    ...marketStats,
    dayOpen: parseNumber(marketStats.dayOpen, BN_ZERO)!,
    dayClose: parseNumber(marketStats.dayClose, BN_ZERO)!,
    dayVolume: parseNumber(marketStats.dayVolume, BN_ZERO)!,
    lastPrice: parseNumber(marketStats.lastPrice, BN_ZERO)!,
    // open_interest: parseNumber(marketStats.open_interest, BN_ZERO)!,
    open_interest: BN_ZERO, // TODO: Check on market open_interest
    marketType: marketStats.marketType as MarketType,
    market: marketStats.marketId,
  };
}

export async function getTokenPrice(denom: string, sdk: CarbonSDK) {
  const price = bnOrZero(await sdk.token.getUSDValue(denom))
  if (price.gt(0)) return price
  try {
    const oraclePrice = bnOrZero((await sdk.query.pricing.TokenPrice({ denom }))?.tokenPrice?.twap).shiftedBy(-SHIFT_DECIMALS)
    return oraclePrice
  }
  catch {
    return BN_ZERO
  }
}

export function truncateStr(str: string = '', frontNum: number = 8, backNum: number = 8, ellipsis: string = '..') {
  // Check if numbers are negative or zero
  // If negative, get absolute value. If zero, assign default value.
  const frontLimit = frontNum === 0 ? 8 : Math.abs(frontNum)
  const backLimit = backNum === 0 ? 8 : Math.abs(backNum)

  if (str.length > 0) {
    if (str.length > frontLimit + backLimit) {
      return `${str.substr(0, frontLimit)}...${str.substr(-backLimit)}`
    }
    return str
  }
  return ''
}

export const snakeToText = (string: string) => {
  return string.split("/")
    .map(snake => snake.split("_")
      .map(substr => substr.charAt(0)
        .toUpperCase() +
        substr.slice(1))
      .join(" "))
    .join("/");
};
